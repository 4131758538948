<template>
  <v-app>
    <div v-if="claim" class="container mt-4 mb-10">
      <div class="mb-2">
        <p class="h2 mt-2">Vet claim</p>
        <span
          >Officer is to ascertain that every field is filled completely</span
        >
        <br />
        <span>
          Batch Name:
          <strong>{{
            getBatchByID(claim.claim_batch).name +
            " " +
            "(" +
            getBatchByID(claim.claim_batch).batch_id +
            ")"
          }}</strong>
        </span>
        <br />
        <span>
          Created on:
          <strong>{{ date }}</strong>
        </span>
      </div>
      <div class="border card">
        <div class="row">
          <div class="col-md-8">
            <table class="table card" v-if="claim">
              <tbody width="100%">
                <tr>
                  <th>FIRST NAME</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter your First Name"
                      v-model="claim.first_name"
                      name="last_name"
                    />
                  </td>
                </tr>

                <tr>
                  <th scope="row">LAST NAME</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter your First Name"
                      v-model="claim.last_name"
                      name="last_name"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">DATE OF BIRTH</th>
                  <td class="col-6">
                    <input
                      type="date"
                      class="form-control"
                      v-model="claim.date_of_birth"
                      name="date_of_birth"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">PATIENT TELEPHONE</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter your phone number"
                      v-model="claim.phone_number"
                      name="phone_number"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">YOUR UNIQUE ID</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter your unique ID here"
                      v-model="claim.unique_id"
                      name="unique_id"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">GUARDIAN NAME</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter your guardian name"
                      v-model="claim.guardian_name"
                      name="guardian_name"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">GENDER</th>
                  <td>
                    <v-overflow-btn
                      class="my-2"
                      :items="sex"
                      label="Select patient gender"
                      v-model="claim.gender"
                      name="gender"
                    ></v-overflow-btn>
                  </td>
                </tr>
                <tr>
                  <th scope="row">ADDRESS</th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter your address"
                      v-model="claim.address"
                      name="address"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-header">PATIENT'S/GUARDIAN SIGNATURE</div>
              <div class="card-body">
                <canvas
                  class="card-text text-black p-5"
                  style="min-height: 100%"
                ></canvas>
              </div>
              <div class="card-footer" style="background-color: #a4dfa2"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="card bg-success W-100">
            <span class="m-3">PRESENTING COMPLAINTS</span>
          </div>
          <div>
            <v-textarea
              filled
              auto-grow
              placeholder="what did the patient complain about"
              v-model="claim.patient_complaints"
              name="patient_complaints"
            ></v-textarea>
          </div>
        </div>
        <div>
          <div class="card bg-success W-100">
            <span class="m-3">EXAMINATION FINDINGS</span>
          </div>
          <div>
            <v-textarea
              name="examination_findings"
              filled
              auto-grow
              placeholder="what did the care provider find out after proper examination of the patient"
              v-model="claim.examination_findings"
            ></v-textarea>
          </div>
        </div>
        <div>
          <div class="card bg-success W-100">
            <span class="m-3">BATCH</span>
          </div>
          <v-overflow-btn
            class="my-2"
            :items="batchNames"
            label="Please select a batch for this claim"
            target="#batch"
            v-model="batch"
            name="claim_batch"
          ></v-overflow-btn>
        </div>

        <div class="card bg-success W-100">
          <span class="m-3">DIAGNOSIS(Please Tick)</span>
        </div>
        <div class="mt-2" style="background-color: #a4dfa2">
          <span class="m-3">OUTPATIENT CONSULTATIONS</span>
        </div>
        <div>
          <v-overflow-btn
            class="my-2"
            :items="consultations"
            label="Select out patient consultation"
            target="#cosultation"
            v-model="claim.out_patient_consultation"
            name="consultation"
          ></v-overflow-btn>
        </div>
        <div class="col-md-12 my-3"></div>
        <div class="mt-2" style="background-color: #a4dfa2">
          <span class="m-3">MANAGEMENT</span>
        </div>
        <div>
          <v-overflow-btn
            class="my-2"
            :items="managements"
            label="Select your management programme"
            target="#management"
            v-model="claim.management"
            name="management"
          ></v-overflow-btn>
        </div>
        <div class="my-3 text-decoration-none">
          <div class="mt-2" style="background-color: #a4dfa2">
            <span class="m-3">DRUGS GIVEN</span>
          </div>
          <div class="row">
            <div class="col-md-7 mt-3">
              <v-textarea
                name="drugs_given"
                rows="1"
                filled
                auto-grow
                placeholder="Drugs given to the patient"
                v-model="claim.drugs_given"
              ></v-textarea>
            </div>
            <div class="col-md-5 mt-3">
              <v-text-field
                type="number"
                name="amount_claimed"
                filled
                auto-grow
                placeholder="Total amount spent to treat the patient ₦"
                v-model="claim.amount_claimed"
              ></v-text-field>
            </div>
          </div>
          <div class="mt-2" style="background-color: #a4dfa2">
            <span class="m-3">FAMILY PLANNING</span>
          </div>
          <div>
            <v-overflow-btn
              class="my-2"
              :items="familyPlannings"
              label="What family planning method are you practising"
              target="#dropdown-example"
              v-model="claim.family_planning"
              name="family_planning"
            ></v-overflow-btn>
          </div>
          <div class="mt-2" style="background-color: #a4dfa2">
            <span class="m-3">CARE GIVER'S DETAIL</span>
          </div>
          <div class="row">
            <div class="col-md-8">
              <table class="table card" width="100%">
                <tbody>
                  <tr>
                    <th>SURNAME</th>
                    <td width="100%">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Surname of care provider"
                        v-model="claim.caregiver_surname"
                        name="caregiver_surname"
                      />
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">FIRST NAME</th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="First name of care provider"
                        v-model="claim.caregiver_firstname"
                        name="caregiver_firstname"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">DATE</th>
                    <td>
                      <input
                        type="date"
                        class="form-control"
                        v-model="claim.caregiver_date_uploaded"
                        name="caregiver_date_uploaded"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">HEALTH FACILITY</th>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name of care provider health facility"
                        v-model="claim.caregiver_health_facility"
                        name="caregiver_health_facility"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-4">
              <div class="mt-1 bgColor">
                <span class="m-3">PATIENTS /GUARDIAN SIGNATURE</span>
              </div>
              <div class="border d-flex" style="height: 85%">
                <label class="mt-auto w-100">
                  HEALTH WORKERS PHONE NUMBER
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Health worker's phone number"
                    v-model="claim.caregiver_phone_number"
                    name="caregiver_phone_number"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <v-row align="start">
          <v-col cols="3" class="ml-4">
            <!-- show progress indicator on load -->
            <v-progress-circular
              v-if="isLoading"
              :width="4"
              :size="30"
              color="green darken-4"
              class="mb-3"
              indeterminate
            ></v-progress-circular>
            <v-btn
              v-else
              medium
              color="green darken-4 white--text w-100"
              @click="saveClaim"
            >
              <small>Save</small>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-container v-else class="h-100"></v-container>
    <v-dialog v-model="error.display" max-width="290">
      <v-card>
        <v-card-title class="headline">Error!</v-card-title>
        <v-card-text
          >Unable to update claim due to {{ error.message }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="error.display = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "update-claim",
  data() {
    return {
      claim: null,
      batch: "",
      sex: ["Male", "Female"],
      error: {
        display: false,
        message: "",
      },
    };
  },
  async created() {
    const claim = await this.fetchClaim({ data: this.$route.params.claim_id });
    if (claim["data"]) {
      this.claim = claim.data;
      this.batch = this.getBatchByID(this.claim.claim_batch).name;
      return;
    }
    this.error.display = true;
    this.error.message = claim.error;
  },
  methods: {
    ...mapActions({
      fetchClaim: "claimModule/fetchClaim",
      updateClaim: "claimModule/updateClaim",
    }),
    async saveClaim() {
      this.claim.claim_batch = this.getBatchByName(this.batch).id;
      const response = await this.updateClaim({ data: this.claim });
      if (response["status"] === 200 || response["status"] === 201) {
        const vm = this;
        this.$router.push({
          name: "view-batch-claim-list",
          params: {
            batch_id: vm.claim.batch_id,
          },
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      familyPlannings: "claimModule/familyPlannings",
      consultations: "claimModule/consultations",
      managements: "claimModule/managements",
      batches: "claimModule/batches",
      batchNames: "claimModule/getBatchNames",
      getBatchByID: "claimModule/getBatchByID",
      getBatchByName: "claimModule/getBatchByName",
      isLoading: "claimModule/isLoading",
    }),
    date() {
      return new Date(this.claim.date_created).toLocaleString();
    },
  },
};
</script>
